import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import Img from "gatsby-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import React, { useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { COLOR } from "../components/header";
import SEO from "../components/seo";
import TrailTitle from "../components/TrailTitle";

const delay = keyframes`
  0% {
    opacity: 0;
    height : 0;
  }

  100% {
    opacity: 0;
    height : initial;
  }
`;

const fade = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const DelayFade = styled(Col)`
  animation: ${delay} ${(props) => props.order * 0.1}s,
    ${fade} 0.1 ${(props) => props.order * 0.1}s;
`;

const BgRow = styled(Row)`
  background-image: url("${(props) => props.background}");
  background-repeat: no-repeat;
  background-position: top right;
`;

export const romanNumberToDec = {
  I: 1,
  II: 2,
  III: 3,
  IV: 4,
  V: 5,
  VI: 6,
  VII: 7,
  VIII: 8,
  IX: 9,
  X: 10,
  XI: 11,
  XII: 12,
};

const QualityPage = ({ location, data }) => {
  const years = useMemo(
    () =>
      Object.keys(
        data.quality.edges.reduce(
          (obj, n) => ({ ...obj, [n.node.year.value]: true }),
          {}
        )
      ).sort((a, b) => b - a),
    [data]
  );

  return (
    <>
      <SEO title='Indikator Mutu Layanan' />
      <Img fluid={data.cover.childImageSharp.fluid} />
      <BgRow className='pt-5' background={data.bg.childImageSharp.fixed.src}>
        <Col
          xs={"12"}
          md={"5"}
          className='d-flex flex-column align-items-center align-content-center'>
          <TrailTitle open className='pb-2 mb-5'>
            <div>Indikator Mutu Layanan</div>
          </TrailTitle>
        </Col>
        <Col xs={"12"} sm={{ span: "8", offset: 2 }}>
          {years.map((y) => (
            <div key={y}>
              <AniLink paintDrip hex={COLOR} to={`/quality/${y}`}>
                <h3 className='mb-5 font-weight-bold'>Tahun {y}</h3>
              </AniLink>
              {/* <h3 className='mb-5 font-weight-bold'>{y}</h3> */}
              <Row className='p-1'>
                {data.quality.edges
                  .filter((e) => e.node.year.value === y)
                  .sort(
                    (a, b) =>
                      romanNumberToDec[b.node.trimester.value] -
                      romanNumberToDec[a.node.trimester.value]
                  )
                  .map(({ node: { trimester, cockpitId } }, idx) => (
                    <DelayFade sm={"3"} key={cockpitId} order={idx}>
                      <Card>
                        <Card.Img
                          variant='top'
                          src={data.logo.childImageSharp.fixed.src}
                        />
                        <Card.Body>
                          <Card.Title>
                            Triwulan {trimester.value} {y}
                          </Card.Title>
                          <AniLink
                            paintDrip
                            hex={COLOR}
                            to={`/quality/${y}/${trimester.value}`}
                            className='btn btn-primary btn-block'>
                            Lihat Laporan
                          </AniLink>
                        </Card.Body>
                      </Card>
                    </DelayFade>
                  ))}
              </Row>
            </div>
          ))}
        </Col>
      </BgRow>
    </>
  );
};

export const query = graphql`
  {
    logo: file(name: { eq: "logo-2023" }) {
      childImageSharp {
        fixed(toFormat: WEBP) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    bg: file(name: { eq: "bg-ring" }) {
      childImageSharp {
        fixed(toFormat: WEBP) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cover: file(name: { eq: "bg-common" }) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: WEBP) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    quality: allCockpitLaporanMutu(
      filter: { display: { value: { eq: true } } }
    ) {
      edges {
        node {
          cockpitId
          cockpitCreated
          year {
            value
          }
          trimester {
            value
          }
        }
      }
    }
  }
`;

export default QualityPage;
